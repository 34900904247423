<template>
  <!-- 协议列表 -->
  <div>
    <headbox title="回收标准" :isbackPath="true" path="person"></headbox>
      <img  src="@/assets/images/recover_short.png" alt="" v-if="!loading">
    <div class="main_info">
      <p>客服热线：400-850-6718</p><br>
      <p>业务咨询：13612855731（陈功）</p><br>
      <p>地址：深圳市罗湖区贝丽北路99号水贝国际中心37楼</p>
    </div>
      <center id="loadingbox" v-if="loading">
        <van-loading  size="70px" />
      </center>
  </div>
</template>
<script>
import headbox from "@/components/head.vue";
import * as commonpro from "@/utils/api";
export default {
  data() {
    return {
          loading:false,
          img:''
    };
  },
 async mounted(){
   this.loading = false
/*   let res = await commonpro.getAboutPhoto()
   res.forEach(item => {
     if (item.eventType == "RECOVER") {
          this.img = item.img
          this.loading = false
     }
   });*/
  },
  components: {
    headbox
  }
};
</script>
<style lang="scss" scoped="true">
#loadingbox {
  margin-top: 150px;
}
::v-deep .van-collapse-item__title:after {
  content: none !important;
}
::v-deep .van-collapse-item__content {
  padding: 0;
}
::v-deep .van-collapse {
  margin: 0 30px;
}
::v-deep .van-icon {
  font-size: 36px;
  margin-top: 35px;
  transform: translate(-50%);
}
::v-deep .van-cell {
  border-bottom: 1px solid #cccccc;
  padding: 0;
}
::v-deep .van-cell__title {
  color: #292929;
  font-size: 32px;
  // margin: 0 30px;
  padding: 35px 0;
  padding-left: 20px;
}
.maxcenter {
  line-height: 48px;
  // margin: 0 30px;
  padding: 30px 0;
  font-size: 26px;
  border-bottom: 1px solid #cccccc;
}
.silderarrow {
  transform: rotate(180deg);
}
.list {
}
.cell > div {
  //  width: 90%;
  //  text-align: right;
  margin-left: 0.3rem;
  //  text-align: center;
  //   vertical-align: middle;
}
img{
  width:100%;
}
.main_info {
  width: 100%;
  background-color: #f03633;
  margin-top: -10px;
  height: 200px;
  font-size: 25px;
  p {
    color: #ffffff;
    margin: 15px 60px 0 75px;
  }
}
</style>
